import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{ref:"listView",attrs:{"fluid":""}},[_c(VDialog,{attrs:{"max-width":"400"},model:{value:(_vm.dialogs.email),callback:function ($$v) {_vm.$set(_vm.dialogs, "email", $$v)},expression:"dialogs.email"}},[_c('GrantorEmail',{key:_vm.dialogs.email,attrs:{"parcel-id":_vm.emailParcelId},on:{"success":function($event){_vm.dialogs.email = false}},model:{value:(_vm.dialogs.email),callback:function ($$v) {_vm.$set(_vm.dialogs, "email", $$v)},expression:"dialogs.email"}})],1),_c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.dialogs.history),callback:function ($$v) {_vm.$set(_vm.dialogs, "history", $$v)},expression:"dialogs.history"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" History "),_c(VIcon,{staticClass:"ml-3",attrs:{"color":"info"}},[_vm._v("mdi-history")])],1),_c(VCardText,[_c('parcel-history',{attrs:{"parcel":_vm.activeParcel}})],1)],1)],1),_c('base-data-table',{attrs:{"items-per-page":_vm.itemsPerPage,"options":_vm.options,"loading":_vm.loading,"headers":_vm.headers,"footer-props":_vm.footerProps,"items":_vm.items},on:{"update:options":_vm.onUpdateOptions},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.removePadding(item))+" ")]}},{key:"item.land",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.landTypeText(item))+" ")]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.address)}})]}},{key:"item.landPreserve",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatPreserve(item.landPreserve))+" ")])]}},{key:"item.solar_system_count",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.metrics && item.metrics.solar ? item.metrics.solar.count : '')+" ")])]}},{key:"item.total_panels",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getSolar(item).numberOfPanels)+" ")])]}},{key:"item.total_kw",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getSolar(item).kw)+" ")])]}},{key:"item.total_cost",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getSolar(item).actualCost || _vm.getSolar(item).estimatedCost)+" ")])]}},{key:"item.first_install_year",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getFirstInstallYear(_vm.getSolar(item)))+" ")])]}},{key:"item.key",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('property-card-key',{attrs:{"data":item,"width":80,"x-small":"","small":false,"textBtn":""},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('span')]},proxy:true}],null,true)})],1)]}},{key:"item.team",fn:function(ref){
var item = ref.item;
return [_c('update-team',{attrs:{"data":_vm.getParcel(item)}})]}},{key:"item.mdd",fn:function(ref){
var item = ref.item;
return [(item.mdd)?_c('span',[_c('span',{style:({ color: _vm.monitoringColor(item.mdd) })},[_vm._v(_vm._s(_vm._f("formatDate")(item.mdd)))])]):_c('span',[_c(VChip,{attrs:{"dark":"","color":_vm.monitoringColor(null, 0),"small":""}},[_vm._v("Today")])],1)]}},{key:"item.lastUpdated",fn:function(ref){
var item = ref.item;
return [_c(VMenu,{attrs:{"offset-y":"","transition":"slide-y-transition","min-width":"130","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({style:(("color: " + (item.mddUpdated ? _vm.monitoringColor(item.mdd) : '')))},on),[_c('base-prop-tooltip',{attrs:{"tooltip":_vm.recentActivityTimestamp(item.recent_activity)}},[_c('span',{class:{ 'error--text': _vm.monitoringIssue(item.recent_activity) }},[(item.recent_activity)?_c('span',[_vm._v(" "+_vm._s(_vm.parseActivity(item.recent_activity))+" ")]):_vm._e(),_c('small',[_vm._v(" "+_vm._s(_vm._f("fromNowRecentActivity")(item.recent_activity))+" ")])])])],1)]}}],null,true)},[(_vm.monitoringIssue(item.recent_activity))?_c(VCard,{attrs:{"outlined":"","color":"error"}},[_c(VCardText,{staticClass:"white--text"},[_vm._v(" Issue: "+_vm._s(_vm.monitoringIssue(item.recent_activity, true))+" ")])],1):_vm._e()],1)]}},{key:"item.detail",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":"primary"},on:{"click":function($event){return _vm.showParcelDetail(item)}}},on),[_vm._v("mdi-card-text")])]}}],null,true)},[_c('span',[_vm._v("Card View")])])]}},{key:"item.contact",fn:function(ref){
var item = ref.item;
return [_c('Contact',{attrs:{"id":item.id,"land-type":_vm.getLandType(item)},on:{"click:email":_vm.onClickEmail}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[(item.landIssueState === 'ISSUE_EXISTS')?_c(VIcon,{attrs:{"color":"warning"}},[_vm._v(" mdi-alert-circle-outline ")]):(item.landIssueState === 'ISSUE_RESOLVED')?_c(VIcon,{attrs:{"color":"success"}},[_vm._v(" mdi-alert-circle-check-outline ")]):_vm._e(),(!_vm.solarAdmin)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":"secondary"},on:{"click":function($event){return _vm.showHistory(item)}}},on),[_vm._v("mdi-history")])]}}],null,true)},[_c('span',[_vm._v("History")])]):_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"to":("/parcel/" + (item.id) + "/solar"),"icon":""}},on),[_c(VIcon,[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])])],1)]}}],null,true)}),_c('div',{staticClass:"ml-7 mb-5 mt-2"},[_c('div',{staticClass:"d-flex flex-wrap align-center"},[_c('h6',{staticClass:"text-h6 total-title"},[_vm._v("Total:")]),_c('ParcelStat',{attrs:{"value":_vm.items}}),_c(VSpacer),(_vm.loggedInAsType === 'organization')?_c(VCheckbox,{staticClass:"mr-2",attrs:{"label":"Show Preserve"},model:{value:(_vm.showPreserveColumn),callback:function ($$v) {_vm.showPreserveColumn=$$v},expression:"showPreserveColumn"}}):_vm._e(),(_vm.loggedInAsType === 'organization')?_c(VCheckbox,{staticClass:"mr-2",attrs:{"label":"Show Contact"},model:{value:(_vm.showContactColumn),callback:function ($$v) {_vm.showContactColumn=$$v},expression:"showContactColumn"}}):_vm._e(),_c(VCheckbox,{attrs:{"label":"Show last activity"},model:{value:(_vm.showLastActivity),callback:function ($$v) {_vm.showLastActivity=$$v},expression:"showLastActivity"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }