<template>
  <v-container fluid ref="listView">
    <v-dialog v-model="dialogs.email" max-width="400">
      <GrantorEmail
        :key="dialogs.email"
        @success="dialogs.email = false"
        v-model="dialogs.email"
        :parcel-id="emailParcelId"
      />
    </v-dialog>
    <v-dialog max-width="600" v-model="dialogs.history">
      <v-card>
        <v-card-title>
          History <v-icon class="ml-3" color="info">mdi-history</v-icon>
        </v-card-title>
        <v-card-text>
          <parcel-history :parcel="activeParcel"></parcel-history>
        </v-card-text>
      </v-card>
    </v-dialog>
    <base-data-table
      :items-per-page="itemsPerPage"
      :options="options"
      @update:options="onUpdateOptions"
      :loading="loading"
      :headers="headers"
      :footer-props="footerProps"
      :items="items"
    >
      <template v-slot:[`item.number`]="{ item }">
        {{ removePadding(item) }}
      </template>

      <template v-slot:[`item.land`]="{ item }">
        {{ landTypeText(item) }}
      </template>

      <template v-slot:[`item.address`]="{ item }">
        <span v-html="item.address"> </span>
      </template>

      <!-- <template v-slot:[`item.parcel_type`]="{ item }">
        <span>
          {{ formatParcelType(item.parcel_type) }}
        </span>
      </template> -->
      <template v-slot:[`item.landPreserve`]="{ item }">
        <span>
          {{ formatPreserve(item.landPreserve) }}
        </span>
      </template>

      <!-- For solar admin -->
      <template v-slot:[`item.solar_system_count`]="{ item }">
        <div>
          {{ item.metrics && item.metrics.solar ? item.metrics.solar.count : '' }}
        </div>
      </template>
      <template v-slot:[`item.total_panels`]="{ item }">
        <div>
          {{ getSolar(item).numberOfPanels }}
        </div>
      </template>
      <template v-slot:[`item.total_kw`]="{ item }">
        <div>
          {{ getSolar(item).kw }}
        </div>
      </template>
      <template v-slot:[`item.total_cost`]="{ item }">
        <div>
          {{ getSolar(item).actualCost || getSolar(item).estimatedCost }}
        </div>
      </template>
      <template v-slot:[`item.first_install_year`]="{ item }">
        <div>
          {{ getFirstInstallYear(getSolar(item)) }}
        </div>
      </template>

      <template v-slot:[`item.key`]="{ item }">
        <div class="d-flex align-center">
          <property-card-key :data="item" :width="80" x-small :small="false" textBtn
            ><template #text><span></span></template
          ></property-card-key>
        </div>
      </template>
      <template v-slot:[`item.team`]="{ item }">
        <update-team :data="getParcel(item)"></update-team>
      </template>
      <template v-slot:[`item.mdd`]="{ item }">
        <span v-if="item.mdd">
          <span :style="{ color: monitoringColor(item.mdd) }">{{ item.mdd | formatDate }}</span>
        </span>
        <span v-else>
          <v-chip dark :color="monitoringColor(null, 0)" small>Today</v-chip>
        </span>
      </template>
      <template v-slot:[`item.lastUpdated`]="{ item }">
        <v-menu offset-y transition="slide-y-transition" min-width="130" max-width="350">
          <template #activator="{ on }">
            <div v-on="on" :style="`color: ${item.mddUpdated ? monitoringColor(item.mdd) : ''}`">
              <base-prop-tooltip :tooltip="recentActivityTimestamp(item.recent_activity)">
                <span :class="{ 'error--text': monitoringIssue(item.recent_activity) }">
                  <span v-if="item.recent_activity">
                    {{ parseActivity(item.recent_activity) }}
                  </span>
                  <small>
                    {{ item.recent_activity | fromNowRecentActivity }}
                  </small>
                </span>
              </base-prop-tooltip>
            </div>
          </template>
          <v-card v-if="monitoringIssue(item.recent_activity)" outlined color="error">
            <v-card-text class="white--text">
              Issue: {{ monitoringIssue(item.recent_activity, true) }}
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
      <template v-slot:[`item.detail`]="{ item }">
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on }">
            <v-icon color="primary" @click="showParcelDetail(item)" v-on="on">mdi-card-text</v-icon>
          </template>
          <span>Card View</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.contact`]="{ item }">
        <Contact :id="item.id" @click:email="onClickEmail" :land-type="getLandType(item)" />
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex justify-end">
          <v-icon v-if="item.landIssueState === 'ISSUE_EXISTS'" color="warning">
            mdi-alert-circle-outline
          </v-icon>
          <v-icon v-else-if="item.landIssueState === 'ISSUE_RESOLVED'" color="success">
            mdi-alert-circle-check-outline
          </v-icon>
          <v-tooltip bottom v-if="!solarAdmin">
            <template v-slot:activator="{ on }">
              <v-icon color="secondary" @click="showHistory(item)" v-on="on">mdi-history</v-icon>
            </template>
            <span>History</span>
          </v-tooltip>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" :to="`/parcel/${item.id}/solar`" icon
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
            </template>
            <span>Edit</span>
          </v-tooltip>
        </div>
      </template>
    </base-data-table>
    <div class="ml-7 mb-5 mt-2">
      <div class="d-flex flex-wrap align-center">
        <h6 class="text-h6 total-title">Total:</h6>
        <ParcelStat :value="items"> </ParcelStat>
        <v-spacer></v-spacer>
        <v-checkbox
          v-model="showPreserveColumn"
          label="Show Preserve"
          class="mr-2"
          v-if="loggedInAsType === 'organization'"
        ></v-checkbox>
        <v-checkbox
          v-model="showContactColumn"
          label="Show Contact"
          class="mr-2"
          v-if="loggedInAsType === 'organization'"
        ></v-checkbox>
        <v-checkbox v-model="showLastActivity" label="Show last activity"></v-checkbox>
      </div>
    </div>
  </v-container>
</template>

<script>
import Land from '../../services/land';
import Parcel from '../../services/parcel';
import { mapGetters } from 'vuex';
import { getStreetAddress, teams, recentActivityFromNow } from '../../utils/parcel';
import { parseTimestamp } from '../../utils/firebase';
import { throttle } from 'throttle-debounce';
import _ from 'lodash';
import moment from 'moment';
import parcelsComputedMixin from '../../mixins/parcelsComputed';

const throttleScroll = throttle(1000, cb => {
  cb();
});

export default {
  mixins: [parcelsComputedMixin],
  components: {
    PropertyCardKey: () => import('./PropertyCardKey'),
    UpdateTeam: () => import('./UpdateTeam'),
    ParcelHistory: () => import('./History.vue'),
    ParcelStat: () => import('./ParcelStat.vue'),
    Contact: () => import('./Contact.vue'),
    GrantorEmail: () => import('../../components/Land/GrantorEmail.vue')
  },
  data: () => ({
    footerProps: {
      itemsPerPageOptions: [10, 20, 50, -1]
    },
    landTypes: Land.landTypes(),
    dialogs: {
      card: false,
      history: false,
      email: false
    },
    options: {},
    activeParcel: null,
    activeParcelID: null,
    emailParcelId: '',
    loaded: {
      options: false,
      scroll: false
    },
    events: {
      search: null,
      scroll: null
    }
  }),
  destroyed() {
    window.removeEventListener('scroll', this.onScroll);
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  watch: {
    itemsPerPage: {
      handler: 'calcPagination',
      immediate: true
    },
    items: {
      handler(value) {
        if (value && value.length) {
          this.updateOptions();
          this.updateScroll();
        }
      }
    }
  },
  filters: {
    fromNowRecentActivity(data) {
      return recentActivityFromNow(data);
    },
    formatDate(date, fmt = 'YYYY-MM-DD') {
      try {
        return moment(date).format(fmt);
      } catch {
        return '';
      }
    }
  },
  computed: {
    loggedInAsType() {
      return this.$store.state.loggedInAs.type;
    },
    showLastActivity: {
      get() {
        return this.$store.state.parcel.mapConfig.showLastActivity;
      },
      set(value) {
        setTimeout(() => {
          this.$store.commit('setMapConfig', { key: 'showLastActivity', value });
        }, 1);
      }
    },
    showPreserveColumn: {
      get() {
        return this.$store.state.parcel.mapConfig.showPreserveColumn;
      },
      set(value) {
        setTimeout(() => {
          this.$store.commit('setMapConfig', { key: 'showPreserveColumn', value });
        }, 100);
      }
    },
    showContactColumn: {
      get() {
        return this.$store.state.parcel.mapConfig.showContactColumn;
      },
      set(value) {
        setTimeout(() => {
          this.$store.commit('setMapConfig', { key: 'showContactColumn', value });
        }, 100);
      }
    },
    loading: {
      get() {
        return this.$store.getters.parcelLoading;
      },
      set(val) {
        this.$store.commit('parcelLoading', val);
      }
    },
    ...mapGetters({
      user: 'user',
      solarDataList: 'solar/dataList',
      solarAdmin: 'isSolarAdmin',
      parcelReference: 'parcelReference'
    }),
    user() {
      return this.$store.state.user;
    },
    headers() {
      let headers = [
        { text: 'Parcel ID', value: 'number' },
        { text: 'Address', value: 'address' },
        { text: 'Preserve', value: 'landPreserve' },
        { text: 'Acres', value: 'acres', align: 'center' },
        { text: 'Detail', value: 'detail' },
        { text: 'Contact', value: 'contact' },
        { text: 'Reference', value: 'reference' },

        { text: 'Land', value: 'land' },
        { text: 'Team', value: 'team' },
        { text: 'Next Monitoring Date', value: 'mdd' },

        { text: 'Key', value: 'key', align: 'center' },
        { text: 'Last Activity', value: 'lastUpdated' },
        { text: 'Action', value: 'action' }
      ];

      const parcelSetting = this.$store.getters.parcelSetting;

      const removeColumn = (items, key) => {
        const keys = [];
        if (typeof key === 'string') [keys.push(key)];
        else if (Array.isArray(key)) {
          keys.push(...key);
        }
        return items.filter(({ value }) => !keys.includes(value));
      };

      if (!parcelSetting.team) {
        headers = removeColumn(headers, 'team');
      }

      if (!this.parcelReference) {
        headers = removeColumn(headers, 'reference');
      }

      if (!this.showPreserveColumn) {
        headers = removeColumn(headers, 'landPreserve');
      }

      if (!this.showContactColumn) {
        headers = removeColumn(headers, 'contact');
      }

      if (this.solarAdmin) {
        headers = [
          { text: 'Parcel ID', value: 'number' },
          {
            text: 'Solar Systems Count',
            value: 'solar_system_count',
            align: 'center'
          },
          { text: 'Total Panels', value: 'total_panels', align: 'center' },
          { text: 'Total kW', value: 'total_kw', align: 'center' },
          { text: 'Total Cost', value: 'total_cost', align: 'center' },
          {
            text: 'First Install Year',
            value: 'first_install_year',
            align: 'center'
          },
          { text: 'Action', value: 'action' }
        ];
      }

      //show land type if in organization mode
      const { type } = this.$store.state.loggedInAs;

      if (type === 'user') {
        headers = removeColumn(headers, ['land', 'mdd', 'landPreserve', 'team', 'detail']);
      }

      if (!this.showLastActivity) {
        headers = removeColumn(headers, 'lastUpdated');
      }

      if (this.mapConfig.team) {
        headers = removeColumn(headers, 'team');
      }

      return headers;
    },
    parcels() {
      return this.$store.getters.parcels;
    },
    teamsComputedMap() {
      return teams(this.parcels, true, true);
    },

    mapConfig() {
      return this.$store.state.parcel.mapConfig;
    },

    landType: {
      get() {
        return this.mapConfig.land;
      },
      set(value) {
        this.$store.commit('setMapConfig', { key: 'land', value });
      }
    },

    teamFilter: {
      get() {
        return this.mapConfig.team;
      },
      set(value) {
        this.$store.commit('setMapConfig', { key: 'team', value });
      }
    },

    filters() {
      return this.$store.state.parcel.mapConfig;
    },

    items() {
      const parcels = [...this.parcelsComputed];
      parcels.map(p => {
        const lastUpdated = (p.lastUpdated =
          p?.recent_activity?.createdAt || p?.recent_activity?.timestamp);
        if (lastUpdated) {
          try {
            p.lastUpdated = parseTimestamp(lastUpdated).getTime();
          } catch {
            p.lastUpdated = 0;
          }
        } else {
          p.lastUpdated = 0;
        }

        p.address = getStreetAddress(p);
        p.landPreserve = p?.metrics?.land?.information?.preserve || '';

        return p;
      });
      return parcels;
    },
    itemsPerPage() {
      return Number(this.$store.state.organizationSetting.listViewRows) || 25;
    }
  },
  methods: {
    onClickEmail(id) {
      this.emailParcelId = id;
      this.dialogs.email = true;
    },
    calcPagination() {
      const rows = this.itemsPerPage;
      const pagination = [10, rows, rows * 2, rows * 4, -1];
      this.footerProps.itemsPerPageOptions = pagination;
    },
    showParcelDetail(parcel) {
      this.$bus.$emit('clickParcel', parcel);
    },
    monitoringColor(date, days) {
      if (days !== undefined) {
        return Land.monitoringColor({ days });
      }
      return Land.monitoringColor({ date1: date, date2: new Date() });
    },
    formatPreserve(preserve) {
      const preserves = this.$store.state.landPreserves;
      const p = preserves?.filter(p => p.value === preserve);
      if (p && p[0]) {
        return p[0]?.text || preserve;
      }
      return _.startCase(preserve);
    },
    onScroll() {
      throttleScroll(() => {
        localStorage.setItem('listViewScroll', window.scrollY);
      });
    },
    updateOptions() {
      if (!this.loaded.options) {
        let opts = localStorage.getItem('listViewOptions');
        if (opts) {
          try {
            opts = JSON.parse(opts);
            this.options = opts;
            this.loaded.options = true;
          } catch {}
        }
      }
    },
    updateScroll() {
      if (!this.loaded.scroll) {
        this.loaded.scroll = true;
        const scroll = Number(localStorage.getItem('listViewScroll'));
        if (scroll && !isNaN(scroll)) {
          setTimeout(() => {
            window.scrollTo({ top: scroll, behavior: 'smooth' });
          }, 500);
        }
      }
    },
    onUpdateOptions(value) {
      if (value && this.loaded.options) {
        localStorage.setItem('listViewOptions', JSON.stringify(value));
      }
    },
    recentActivityTimestamp(activity) {
      const ts = activity?.timestamp || activity?.createdAt;
      return this.formatTimestamp(parseTimestamp(ts));
    },
    monitoringIssue(activity, issue = false) {
      if (activity?.type === 'land') {
        if (activity.detail?.issueDescription) {
          return issue ? activity.detail?.issueDescription : true;
        }
      }
      return false;
    },
    formatParcelType(type) {
      const p = new Parcel();
      return p.type(type);
    },
    getLandType(parcel) {
      const p = new Parcel({ parcel });
      return p.getLandType();
    },
    landTypeText(parcel) {
      const p = new Parcel({ parcel });
      const type = p.getLandType();
      return Land.landType(type)?.shortName || '';
    },
    getParcel(parcel) {
      return {
        ...this.$store.getters.parcels.filter(e => e.id == parcel.id)[0]
      };
    },
    removePadding(parcel) {
      const p = new Parcel({ parcel });
      return p.removePadding();
    },
    getStreetAddress(parcel) {
      const p = new Parcel({ parcel });
      return p.getStreetAddress();
    },
    setActiveParcel(parcel) {
      this.activeParcel = this.getParcel(parcel);
      this.activeParcelID = parcel.id;
    },
    showHistory(parcel) {
      this.setActiveParcel(parcel);
      this.dialogs.history = true;
    },
    updateCurrent(data) {
      this.activeParcel = { ...data };
    },
    getSolar(parcel) {
      let res = this.solarDataList.filter(e => e.id == parcel.id)[0];
      if (!res) {
        return {};
      }
      res = res.solar_systems[0];
      return res || {};
    },
    getFirstInstallYear(data) {
      let date = '';
      if (data.finalBuildingDate && data.finalWiringDate) {
        date = _.max([new Date(data.finalBuildingDate), new Date(data.finalWiringDate)]);
      } else if (data.finalBuildingDate) {
        date = new Date(data.finalBuildingDate);
      } else if (data.finalWiringDate) {
        date = new Date(data.finalWiringDate);
      } else {
        date = '';
      }
      if (date) {
        date = date.toISOString().split('T')[0];
      }
      return date;
    }
  }
};
</script>

<style>
</style>
